import {
  ContactShadows,
  Float,
  OrbitControls,
  Preload,
  Sphere,
  // useGLTF,
  useTexture,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense } from "react";
import CanvasLoader from "../Loader";

const Computers = () => {
  // const computer = useGLTF("./purple_planet/scene.gltf");

  // const [baseColor] = useLoader(TextureLoader, [
  //   "./low_poly_http://localhost:3005/computer/textures/base_baseColor.png",
  // ]);
  // const [colorMap, roughnessMap, aoMap] = useTexture([
  //   // "./purple_planet/textures/PurplePlanet_normal.png",
  //   "./purple_planet/textures/PurplePlanet_baseColor.png",
  //   "./purple_planet/textures/PurplePlanet_metallicRoughness.png",
  //   "./purple_planet/textures/Clouds_1_normal.png",
  // ]);

  const [colorMap, aoMap, roughnessMap, normalMap,displacementMap, lightMap] = useTexture([
    "./snow/GroundSnowPitted003_COL_1K.jpg",
    "./snow/GroundSnowPitted003_AO_1K.jpg",
    "./snow/GroundSnowPitted003_BUMP_1K.jpg",
    "./snow/GroundSnowPitted003_NRM_1K.jpg",
    './snow/GroundSnowPitted003_DISP_1K.jpg',
    "./snow/GroundSnowPitted003_REFL_1K.jpg"
  ])


  return (
    <mesh>
      <ambientLight intensity={0.1} />
      <directionalLight intensity={2} />
      <hemisphereLight intensity={0.1} />
      <spotLight
        position={[10, 5, 5]}
        angle={1}
        penumbra={1}
        intensity={1}
        castShadow
        color="red"
        shadow-mapSize={1024}
      />

      <ambientLight position={[10, 10, 10]} intensity={1} color="green" />

      <Float speed={1} floatIntensity={0.2} rotationIntensity={1.2} scale={1.5}>
        {/* <primitive object={computer.scene} /> */}

        <Sphere>
          <meshStandardMaterial
            aoMap={aoMap}
            normalMap={normalMap}
            map={colorMap}
            bumpScale={1}
            displacementMap={displacementMap}
            displacementBias={0}
            aoMapIntensity={2}
            bumpMap={roughnessMap}
            displacementScale={0}
            roughnessMap={roughnessMap}
            lightMap={lightMap}
            
          />
        </Sphere>
        {/* <Sphere scale={2} args={[1.2, 1000, 1000]}>
          <meshStandardMaterial
            map={plasterDiff}
            normalMap={plasterNormal}
            roughnessMap={plasterRough}
            bumpScale={2000}
            bumpMap={plasterDiff}
          /> */}
        {/* <meshStandardMaterial
            map={plasterDiff}
            normalMap={plasterNormal}
            roughnessMap={plasterRough}
          /> */}
        {/* </Sphere> */}
        {/* <meshStandardMaterial
            normalMap={normalMap}
            depthWrite
            map={colorMap}
            displacementScale={1}
            aoMap={aoMap}
            roughnessMap={roughnessMap}
            wireframe
            color="red"
          /> */}
      </Float>
      <ContactShadows
        opacity={2}
        scale={20}
        blur={10}
        far={10}
        resolution={256}
        color="white"
      />
    </mesh>
  );
};

const ComputersCanvas = () => {
  return (
    <Canvas
      className="computer_showcase"
      frameloop="demand"
      shadows="variance"
      camera={{ position: [20, 34, 5], fov: 7 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          autoRotateSpeed={1.5}
          autoRotate
          maxPolarAngle={Math.PI / 1.8}
          minPolarAngle={Math.PI / 1.8}
        />
        <Computers />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default ComputersCanvas;
