import React from "react";
import { styles } from "../styles";
import { ComputersCanvas } from "./canvas";
import Typed from "typed.js";
import { useRef } from "react";

export const Hero = () => {
  const el = useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["user interfaces", "3D visuals &amp; web applications"],
      typeSpeed: 40,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <section className="relative w-full h-screen mx-auto flex items-center justify-center flex-col">
      <div
        className={`${styles.paddingX} absolute inset-0 max-w-7xl mx-auto flex flex-row items-start gap-5 top-[50%] translate-y-[-50%] justify-center items-center`}
      >
        <div className="flex flex-col justify-center items-center mt-5 align-middle">
          <div className="w-5 h-5 rounded-full bg-[#915eff]" />
          <div className="w-1 sm:h-80 h-40 violet-gradient" />
        </div>
        <div className="bg-purple-950 bg-opacity-30 p-10">
          <h1 className={`${styles.heroHeadText} text-white mt-10`}>
            <span className="font-extralight">Hi, I'm</span>&nbsp;
            <span className="text-[#915eff] font-bold">Ujjwal</span>
          </h1>
          <p className={`${styles.heroSubText} mt-2 text-white-100`}>
            I develop <span className="text-[#915eff]" ref={el}></span>
          </p>
        </div>
      </div>

      <ComputersCanvas />
    </section>
  );
};
