import React from "react";
import AnimatedCursor from "react-animated-cursor";
import { BrowserRouter } from "react-router-dom";
import {
  About,
  Contact,
  Experience,
  FeedBacks,
  Hero,
  Navbar,
  StarsCanvas,
  Works,
  Tech,
} from "./components";

const App = () => {
  return (
    <div>
      <AnimatedCursor
        color="255,255,255"
        innerSize={8}
        outerSize={35}
        innerScale={3}
        outerScale={1.7}
        outerAlpha={0}
        innerStyle={{
          mixBlendMode: "difference",
        }}
        outerStyle={{
          border: "3px solid #d9d9d9",
        }}
      />

      <BrowserRouter>
        <div className="relative z-0 bg-primary">
          <div className="bg-cover bg-no-repeat bg-center">
            <Navbar />
            <Hero />
          </div>
          <About />
          <Experience />
          <Tech />
          <Works />
          <FeedBacks />

          <div className="relative z-0">
            {/*  */}
            <Contact />
            <StarsCanvas />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
